import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { breakpoints, Container } from "../utils/styles"
import SEO from "../components/seo"

const AlbumGrid = styled.div`
  margin: auto;
  text-align: center;

  @media (min-width: ${breakpoints.l}px) {
    padding: 3rem 0 5rem;
  }
`

const AlbumItem = styled.span`
  font-family: "Swear-Display-Light", serif;
  font-weight: light;
  color: #000000;
  font-size: 2.5rem;
  margin: 0 1rem;
  display: block;
  text-align: center;
  line-height: 1;

  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
  }

  @media (max-width: 420px) {
    font-size: 1.35rem;
  }

  @media (max-width: 350px) {
    font-size: 1.1rem;
  }

  @media (min-width: ${breakpoints.m}px) {
    font-size: 4rem;
    margin: 0 1.5rem;
  }
`

const TourGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 21px;

  @media (min-width: ${breakpoints.l}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${breakpoints.xxl}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const TourItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;

  & div {
    font-size: 0.7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (min-width: ${breakpoints.s}px) {
    display: grid;
    grid-template-columns: 1fr 130px;

    & div {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
`

const TourCity = styled.span`
  font-family: "Swear-Display-Light", monospace;
  font-weight: light;
  font-size: 1.35rem;
  height: 100%;
  display: flex;
  align-items: center;
`

const TourDateVenue = styled.span`
  text-align: left;
  font-family: "HelveticaNeue-Regular";
  text-transform: uppercase;
  padding: 0;

  @media (min-width: ${breakpoints.m}px) {
    padding: 0 0.75rem;
  }
`

const BuyButton = styled.a`
  background: transparent;
  border: 1px solid #000000;
  color: #000000;
  font-family: "HelveticaNeue-Regular";
  font-weight: 300;
  border-radius: 20px;
  padding: 0 12px;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.7rem;
  min-width: 110px;

  &:hover {
    background: #000000;
    color: white;
  }

  @media (min-width: ${breakpoints.m}px) {
    width: 130px;
  }

  @media (min-width: ${breakpoints.xl}px) {
    font-size: 0.8rem;
  }
`

const Placeholder = styled.div`
  & p {
    font-family: "HelveticaNeue-Regular";
    color: #000000;
    font-size: 1rem;
    letter-spacing: -0.6px;
    text-transform: uppercase;
    text-align: center;
    padding-top: 2.5rem;
  }
`

const isValidURL = string => {
  let res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  )
  return res !== null
}

const TourPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicTourDate {
        edges {
          node {
            data {
              city
              date
              ticket_url
              venue
            }
          }
        }
      }
    }
  `)

  let tourItemsSorted = data.allPrismicTourDate.edges.sort((a, b) => {
    let dateA = new Date(a.node.data.date)
    let dateB = new Date(b.node.data.date)

    return dateA - dateB
  })

  return (
    <>
      <SEO title="Home" />
      <Container>
        <AlbumGrid>
          <AlbumItem>EUPHORIC RECALL</AlbumItem>
        </AlbumGrid>
        {tourItemsSorted.length > 1 ? (
          <TourGrid>
            {tourItemsSorted.map((item, index) => {
              const { city, date, venue, ticket_url } = item.node.data

              return (
                <TourItem key={index}>
                  <div>
                    <TourCity>{city}</TourCity>
                    <TourDateVenue>
                      {date} &#8212; {venue}
                    </TourDateVenue>
                  </div>
                  {ticket_url && (<BuyButton
                    href={!isValidURL(ticket_url) ? "#" : ticket_url}
                    disabled={!isValidURL(ticket_url)}
                  >
                    Buy Tickets
                  </BuyButton>)}
                </TourItem>
              )
            })}
          </TourGrid>
        ) : (
          <Placeholder>
            <p>No tour dates currently scheduled. More to come soon.</p>
          </Placeholder>
        )}
      </Container>
    </>
  )
}

export default TourPage
